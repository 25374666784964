import React from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  FormControl,
  FormHelperText
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

function AutocompleteField(props) {
  const { label, name, data, onSetValue, readOnly, ...rest } = props;
  const [field, meta, helpers] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;
  const _renderHelperText = () => {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }

    return false;
  };

  return (
    <FormControl {...rest} error={isError}>
      <Autocomplete
        {...field}
        id={name}
        options={data}
        getOptionLabel={option => option.label}
        value={data.find(item => item.value === selectedValue) || null}
        readOnly={readOnly}
        onChange={(event, newValue) => {
          helpers.setValue(newValue ? newValue.value : '');
          
          if (onSetValue) {
            onSetValue(newValue ? newValue.value : '');
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.key}>
            {option.label}
          </li>
        )}
      />
      {_renderHelperText()}
    </FormControl>
  );
}

AutocompleteField.defaultProps = {
  data: []
};

AutocompleteField.propTypes = {
  data: PropTypes.array
};

export default AutocompleteField;
