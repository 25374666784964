export default {
  formId: 'pegawaiForm',
  formField: {
    kode: {
      name: 'kode',
      label: 'Kode Pegawai*',
      requiredErrorMsg: 'Kode pegawai harus diisi'
    },
    idAnggota: {
      name: 'idAnggota',
      label: 'Id Anggota'
    },
    nama: {
      name: 'nama',
      label: 'Nama*',
      requiredErrorMsg: 'Nama harus diisi'
    },
    namaTanpaGelar: {
      name: 'namaTanpaGelar',
      label: 'Nama Tanpa Gelar'
    },
    jenisKelamin: {
      name: 'jenisKelamin',
      label: 'Jenis Kelamin*',
      requiredErrorMsg: 'Jenis kelamin harus diisi'
    },
    tempatLahir: {
      name: 'tempatLahir',
      label: 'Tempat Lahir*',
      requiredErrorMsg: 'Tempat lahir harus diisi'
    },
    tglLahir: {
      name: 'tglLahir',
      label: 'Tanggal Lahir*',
      requiredErrorMsg: 'Tanggal lahir harus diisi'
    },
    statusPerkawinan: {
      name: 'statusPerkawinan',
      label: 'Status Perkawinan*',
      requiredErrorMsg: 'Status perkawinan harus diisi'
    },
    agama: {
      name: 'agama',
      label: 'Agama*',
      requiredErrorMsg: 'Agama harus diisi',
    },
    pendidikan: {
      name: 'pendidikan',
      label: 'Pendidikan*',
      requiredErrorMsg: 'Pendidikan harus diisi'
    },
    ukuranBaju: {
      name: 'ukuranBaju',
      label: 'Ukuran Baju'
    },
    provinsi: {
      name: 'provinsi',
      label: 'Provinsi*',
      requiredErrorMsg: 'Provinsi harus diisi'
    },
    kotaKab: {
      name: 'kotaKab',
      label: 'Kota/Kabupaten*',
      requiredErrorMsg: 'Kota/Kabupaten harus diisi'
    },
    kecamatan: {
      name: 'kecamatan',
      label: 'Kecamatan*',
      requiredErrorMsg: 'Kecamatan harus diisi'
    },
    alamat: {
      name: 'alamat',
      label: 'Alamat*',
      requiredErrorMsg: 'Alamat harus diisi'
    },
    provinsiDomisili: {
      name: 'provinsiDomisili',
      label: 'Provinsi Domisili'
    },
    kotaKabDomisili: {
      name: 'kotaKabDomisili',
      label: 'Kota/Kabupaten Domisili'
    },
    kecamatanDomisili: {
      name: 'kecamatanDomisili',
      label: 'Kecamatan Domisili'
    },
    alamatDomisili: {
      name: 'alamatDomisili',
      label: 'Alamat Domisili'
    },
    useDomisili: {
      name: 'useDomisili',
      label: 'Gunakan alamat domisili'
    },
    noRekening: {
      name: 'noRekening',
      label: 'Nomor Rekening'
    },
    noHp: {
      name: 'noHp',
      label: 'Nomor HP'
    },
    nik: {
      name: 'nik',
      label: 'NIK*',
      requiredErrorMsg: 'NIK harus diisi'
    },
    npwp: {
      name: 'npwp',
      label: 'NPWP'
    },
    bpjs: {
      name: 'bpjs',
      label: 'Nomor BPJS'
    },
    email: {
      name: 'email',
      label: 'Email*',
      emailErrorMsg: 'Email tidak valid',
      requiredErrorMsg: 'Email harus diisi'
    },
    tglMasuk: {
      name: 'tglMasuk',
      label: 'Tanggal Masuk*',
      requiredErrorMsg: 'Tanggal masuk harus diisi'
    },
    tglKeluar: {
      name: 'tglKeluar',
      label: 'Tanggal Keluar'
    },
    kantor: {
      name: 'kantor',
      label: 'Kantor*',
      requiredErrorMsg: 'Kantor harus diisi'
    },
    jabatan: {
      name: 'jabatan',
      label: 'Jabatan*',
      requiredErrorMsg: 'Jabatan harus diisi'
    },
    integritas: {
      name: 'integritas',
      label: 'Fakta Integritas',
    },
    statusPegawai: {
      name: 'statusPegawai',
      label: 'Status Pegawai*',
      requiredErrorMsg: 'Status pegawai harus diisi'
    },
    tglStatus: {
      name: 'tglStatus',
      label: 'Tanggal PKWTT'
    },
    tglMulaiKontrak: {
      name: 'tglMulaiKontrak',
      label: 'Tanggal Mulai Kontrak'
    },
    tglAkhirKontrak: {
      name: 'tglAkhirKontrak',
      label: 'Tanggal Akhir Kontrak'
    },
    kontrakMagangKe: {
      name: 'kontrakMagangKe',
      label: 'Magang Ke'
    },
    kontrakPkwtKe: {
      name: 'kontrakPkwtKe',
      label: 'PKWT Ke'
    },
    notes: {
      name: 'notes',
      label: 'Notes'
    },
    profilUrl: {
      id: 'profil-url',
      name: 'profilUrl',
      label: 'Foto Profil',
      requiredErrorMsg: 'Foto profil harus dipilih',
      toBig: 'Ukuran file terlalu besar'
    },
    ttdUrl: {
      id: 'ttd-url',
      name: 'ttdUrl',
      label: 'Foto Tanda Tangan',
      toBig: 'Ukuran file terlalu besar'
    }
  }
};
