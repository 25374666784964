import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useMemo } from 'react';
// @mui
import {
  Stack,
  Card,
  Container,
  Avatar,
  Typography,
  Grid
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { id } from "date-fns/locale";
import { format, parseISO } from 'date-fns';
import MUIDataTable from "mui-datatables";
import { TableLoader } from '../../../components/table';
import Label from '../../../components/label';
import { getCurrentDate, customDate, dateDiff } from "../../../utils/formatDate";
// API
import { axiosPrivate } from '../../../common/axiosPrivate';

export default function PegawaiMasukPage() {
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const BodyComponent = useMemo(
    () => (props) => (
      <TableLoader loading={tableLoading} {...props} />
    ),
    [tableLoading]
  );

  const columns = [
    {
      name: "_id",
      options: {
        display: false,
        filter: false,
        sort: false
      }
    },
    {
      name: "number",
      label: "No",
      options: {
        filter: false,
        sort: false,
        customBodyRender : (value, tableMeta) => {
          const rowIndex = Number(tableMeta.rowIndex) + 1;
          return rowIndex;
        }
      },
    },
    {
     name: "nama",
     label: "Nama",
     options: {
      filter: false,
      sort: true,
      customBodyRender: (nama, meta) => {
        const profilUrlId = meta.rowData[11];
        const profilUrl = profilUrlId ? `https://drive.google.com/thumbnail?id=${profilUrlId}` : '';
        return (
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={profilUrl} src={profilUrl} imgProps={{ referrerPolicy: "no-referrer" }} />
            <Typography variant="subtitle2" noWrap>
              {nama}
            </Typography>
          </Stack>
        )
      }
     }
    },
    {
     name: "kodePegawai",
     label: "Kode",
     options: {
      filter: false,
      sort: false,
     }
    },
    {
      name: "statusAktif",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (data) => {
          let color;
    
          switch (data) {
            case 'Aktif':
              color = 'success';
              break;
            case 'Keluar':
              color = 'error';
              break;
            default:
              color = 'default';
              break;
          }
    
          return <Label color={color}>{data}</Label>;
        }
      }
    },
    {
      name: "tglMasuk",
      label: "Tgl Masuk",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => Object.prototype.toString.call(value) ? format(parseISO(value), 'dd/MM/yyyy') : format(new Date(value), 'dd/MM/yyyy'),
        filterType: 'custom',
        customFilterListOptions: {
          render: v => {
            if (v[0] && v[1]) {
              const startDate = format(new Date(v[0]), 'dd/MM/yyyy');
              const enDate = format(new Date(v[1]), 'dd/MM/yyyy');

              return [`Masuk Dari: ${startDate}`, `Sampai Dengan: ${enDate}`];
            } if (v[0]) {
              const startDate = format(new Date(v[0]), 'dd/MM/yyyy');

              return `Masuk Dari: ${startDate}`;
            } if (v[1]) {
              const enDate = format(new Date(v[1]), 'dd/MM/yyyy');

              return `Sampai Dengan: ${enDate}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          fullWidth: true,
          names: [],
          logic(date, filters) {
            const formated = new Date(customDate(date));

            if (filters[0] && filters[1]) {
              return !(formated >= filters[0] && formated <= filters[1]);
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <>
              <Grid container spacing={3} >
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider adapterLocale={id} dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Masuk Dari"
                      value={filterList[index][0] || null}
                      onChange={date => {
                        filterList[index][0] = date;
                        onChange(filterList[index], index, column);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: 'standard'
                        },
                        actionBar: { actions: ['clear'] },
                      }}
                    />
                </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider adapterLocale={id} dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Sampai Dengan"
                      value={filterList[index][1] || null}
                      onChange={date => {
                        filterList[index][1] = date;
                        onChange(filterList[index], index, column);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          variant: 'standard'
                        },
                        actionBar: { actions: ['clear'] },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </>
          ),
        },
      }
    },
    {
      name: "tglKeluar",
      label: "Tgl Keluar",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => value ? format(parseISO(value), 'dd/MM/yyyy') : ''
      }
    },
    {
      name: "tglMasuk",
      label: "Lama Kerja",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, meta) => {
          const tglKeluar = meta.rowData[6];

          if(tglKeluar) {
            return getDateDiff(format(parseISO(value), 'yyyy-MM-dd'), format(parseISO(tglKeluar), 'yyyy-MM-dd'))
          }
          
          return getDateDiff(format(parseISO(value), 'yyyy-MM-dd'), getCurrentDate('-'))
        }
      }
    },
    {
      name: "dibuatOleh",
      label: "Dibuat Oleh",
      options: {
        filter: false,
        sort: true,
        display: false
      }
    },
    {
      name: "tglBuat",
      label: "Tgl Buat",
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value) => value ? format(parseISO(value), 'dd/MM/yyyy') : ''
      }
    },
    {
      name: "tglUbah",
      label: "Tgl Ubah",
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value) => value ? format(parseISO(value), 'dd/MM/yyyy') : ''
      }
    },
    {
      name: "profilUrlId",
      label: "Google Drive Photo ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      }
     }
   ];
   
  const options = {
    filterType: "dropdown",
    selectableRowsHideCheckboxes: true,
    downloadOptions: {
      filename: `pegawai-masuk-${getCurrentDate('-')}.csv`,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      }
    }
  };

  const getDateDiff = (startDate, endDate) => {
    const { years, months, days } = dateDiff(startDate, endDate);

    let result = '';
    if (years > 0) {
        result += `${years} Tahun `;
    }
    if (months > 0) {
        result += `${months} Bulan `;
    }
    if (days > 0) {
        result += `${days} Hari `;
    }

    return result.trim(); // Remove trailing space
  };

  // Fetch Api
  useEffect(() => {
    axiosPrivate.get('pegawai/masuk')
    .then((response) => {
      // console.log(response);
      
      setData(response?.data)
    })
    .catch((error) => console.log(error))
    .finally(() => setTableLoading(false))
  }, []);

  return (
    <>
      <Helmet>
        <title> List Pegawai | Sip App </title>
      </Helmet>

      <Container>
        <Card>
          <MUIDataTable
            title="List Pegawai Masuk"
            data={data}
            columns={columns}
            options={options}
            components={{ TableBody: BodyComponent }}
          />
        </Card>
      </Container>
    </>
  );
}
