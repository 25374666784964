import { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { Container, Typography, Stack, Box, Button, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { axiosPrivate } from '../../../common/axiosPrivate';
import NotificationContext from '../../../context/notificationContext';
import AuthContext from '../../../context/authContext';

import ProfilForm from '../../../sections/@dashboard/pegawai/forms/ProfilForm';
import pegawaiFormModel from '../../../sections/@dashboard/pegawai/form-model/pegawaiFormModel';
import validationSchema from '../../../sections/@dashboard/pegawai/form-model/validationSchema';

const ProfilPegawaiPage = ({id, handleCloseModal, handleUpdateTable}) => {
  const notificationCtx = useContext(NotificationContext);
  const { user } = useContext(AuthContext);
  const [dropDown, setDropDown] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const { formId, formField } = pegawaiFormModel;
  const [initialValues, setInitialValues] = useState({});

  const _handleSubmit = async (values, actions) => {
    const params = {
      kodePegawai: values?.kode,
      idAnggota: values?.idAnggota,
      nama: values?.nama,
      namaTanpaGelar: values?.namaTanpaGelar,
      jenisKelamin: values?.jenisKelamin,
      tempatLahir: values?.tempatLahir,
      tglLahir: moment(values?.tglLahir).format('YYYY-MM-DD'),
      statusPerkawinan: values?.statusPerkawinan,
      pendidikan: values?.pendidikan,
      agama: values?.agama,
      ukuranBaju: values?.ukuranBaju,
      provinsi: values?.provinsi,
      kecamatan: values?.kecamatan,
      kotaKabupaten: values?.kotaKab,
      alamat: values?.alamat,
      domisili: values?.useDomisili,
      provinsiDomisili: values?.provinsiDomisili,
      kecamatanDomisili: values?.kecamatanDomisili,
      kotaKabupatenDomisili: values?.kotaKabDomisili,
      alamatDomisili: values?.alamatDomisili,
      dibuatOleh: user?._id,
      tglUbah: moment().format('YYYY-MM-DDTHH:mm:ss')
    };

    await axiosPrivate.put(`/pegawai/update-partial/${id}`, params)
    .then((response) => {
      const { result } = response?.data;

      handleUpdateTable(result);
      handleMessage(response?.data);
    })
    .catch((error) => {
      console.log(error);
      handleMessage(error)
    })
    .finally(() => {
      actions.setSubmitting(false);
    });
  }

  const handleMessage = (params) => {
    if(params?.success) {
      notificationCtx.setup({
        severity: 'success',
        title: 'Success',
        message: 'Data Berhasil Diubah.'
      });
    } else {
      notificationCtx.setup({
        severity: 'error',
        title: 'Error',
        message: params?.response
      })
    }
  }

  useEffect(() => {
    setPageLoading(true);
    const getDropdown = async () => {
      await axiosPrivate.get('pegawai/dropdown/list')
      .then((response) => {
        const data = response?.data;
        
        setDropDown(data);
      })
      .catch((error) => console.log(error));
    }

    getDropdown()
    .finally(() => {
      axiosPrivate.get(`/pegawai/${id}`)
      .then((response) => {
        const data = response?.data;

        setInitialValues({
          kode: data?.kodePegawai,
          idAnggota: data?.idAnggota,
          nama: data?.nama,
          namaTanpaGelar: data?.namaTanpaGelar,
          jenisKelamin: data?.jenisKelamin,
          tempatLahir: data?.tempatLahir,
          tglLahir: moment(data?.tglLahir).format('YYYY-MM-DD'),
          statusPerkawinan: data?.statusPerkawinan,
          pendidikan: data?.pendidikan,
          agama: data?.agama,
          ukuranBaju: data?.ukuranBaju,
          provinsi: data?.provinsi,
          kecamatan: data?.kecamatan,
          kotaKab: data?.kotaKabupaten,
          alamat: data?.alamat,
          domisili: data?.domisili,
          provinsiDomisili: data?.provinsiDomisili,
          kecamatanDomisili: data?.kecamatanDomisili,
          kotaKabDomisili: data?.kotaKabupatenDomisili,
          alamatDomisili: data?.alamatDomisili,
          useDomisili: data?.domisili
        });
      })
      .catch((error) => console.log(error?.response))
      .finally(() => setPageLoading(false));
    });
  }, [id]);

  return (
    <>
      {pageLoading ? (
        <Box
          sx={{
            flexGrow: 1
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={"100%"}
          width={"100%"}
        >
          <CircularProgress />
        </Box>) : (
          <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Ubah Profil
            </Typography>
          </Stack>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema[0]}
            onSubmit={_handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form id={formId}>
                <ProfilForm formField={formField} dropDown={dropDown} values={initialValues} />
                <Stack
                  direction="row"
                  justifyContent="end"
                  mt={2}
                  spacing={1}
                >
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleCloseModal}
                  >
                    Kembali
                  </Button>
                  <LoadingButton
                    type="submit"
                    loading={isSubmitting}
                    variant="contained"
                  >
                    Simpan
                  </LoadingButton>
                </Stack>
              </Form>
            )}
          </Formik>
        </Container>
        )}
    </>
  );
};
  
export default ProfilPegawaiPage;
