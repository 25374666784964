import { useContext } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import CabangLayout from './layouts/cabang';
//
import KantorPage from './pages/master/kantor/KantorPage';
import JabatanPage from './pages/master/jabatan/JabatanPage';
import KotaKabupatenPage from './pages/master/kota-kabupaten/KotaKabupatenPage';
import KecamatanPage from './pages/master/kecamatan/KecamatanPage';
import PegawaiPage from './pages/kepegawaian/pegawai/PegawaiPage';
import ImportPegawaiPage from './pages/kepegawaian/pegawai/ImportPegawaiPage';
import PegawaiMasukPage from './pages/kepegawaian/pegawai/PegawaiMasukPage';
import PegawaiKeluarPage from './pages/kepegawaian/keluar/PegawaiKeluarPage';
import PromosiPage from './pages/kepegawaian/promosi/PromosiPage';
import MutasiPage from './pages/kepegawaian/mutasi/MutasiPage';
import LaporanPromosiMutasi from './pages/kepegawaian/laporan/LapPromosiMutasiPage';
import LaporanKinerja from './pages/kepegawaian/laporan/LapKinerjaPage';
import LapKinerjaKantorPage from './pages/kepegawaian/laporan/LapKinerjaKantorPage';
import LapPegawaiKontrakPage from './pages/kepegawaian/laporan/LapPegawaiKontrakPage';
import LaporanAbsen from './pages/kepegawaian/laporan/LapAbsenPage';
import LaporanHistoryAbsen from './pages/kepegawaian/laporan/LapHistoryAbsenPage';
import HistoryCutiPage from './pages/kepegawaian/laporan/HistoryCutiPage';
import SaldoCutiPage from './pages/kepegawaian/cuti/SaldoCutiPage';
import CutiRequestPage from './pages/kepegawaian/cuti/CutiRequestPage';
import ProcessCutiPage from './pages/kepegawaian/cuti/ProcessCutiPage';
import ReqCutiPage from './pages/cabang/cuti/ReqCutiPage';
import CabangHistoryCutiPage from './pages/cabang/laporan/HistoryCutiPage';
import ImportSaldoCutiPage from './pages/kepegawaian/cuti/ImportSaldoCutiPage';
import DokAllPegawaiPage from './pages/kepegawaian/dokumen/DokAllPegawaiPage';
import AbsenPage from './pages/kepegawaian/absen/AbsenPage';
import AbsenRumpunPage from './pages/kepegawaian/absen-rumpun/AbsenRumpunPage';
import BirthdayPage from './pages/kepegawaian/birthday/BirthdayPage';
import CabangAbsenPage from './pages/cabang/absen/CabangAbsenPage';
import CabangAbsenRumpunPage from './pages/cabang/absen-rumpun/CabangAbsenRumpunPage';
import CabangLapHistoryAbsenPage from './pages/cabang/laporan/LapHistoryAbsenPage';
import UserPage from './pages/user/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import AuthContext from './context/authContext';

// ----------------------------------------------------------------------

export default function Router() {
  const { user } = useContext(AuthContext);
  const role = user?.role;
  
  const isAdminOrPusat = role === 'admin' || role === 'pusat';
  const isAbsen = role === 'absen';
  const isValidRole = isAdminOrPusat || isAbsen;

  const routes = useRoutes([
    {
      path: '/',
      element: isValidRole
        ? isAdminOrPusat
          ? <Navigate to="/dashboard/app" />
          : isAbsen
            ? <Navigate to="/cabang/absen" />
            : null // Handle other roles as needed
        : <Navigate to="/login" />,
    },
    {
      path: 'login',
      element: isValidRole
        ? isAdminOrPusat
          ? <Navigate to="/dashboard/app" />
          : isAbsen
            ? <Navigate to="/cabang/absen" />
            : null // Handle other roles as needed
        : <LoginPage />,
    }, 
    isAbsen && {
      path: 'cabang',
      element: <CabangLayout />,
      children: [
        { path: 'absen', element: <CabangAbsenPage /> },
        { path: 'absen-rumpun', element: <CabangAbsenRumpunPage /> },
        { path: 'req-cuti', element: <ReqCutiPage /> },
        { path: 'laporan/history-absen-pegawai', element: <CabangLapHistoryAbsenPage /> },
        { path: 'laporan/history-cuti', element: <CabangHistoryCutiPage /> },
      ],
    },
    isAdminOrPusat && {
      path: '/dashboard',
      element: user ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'master/kantor', element: <KantorPage /> },
        { path: 'master/jabatan', element: <JabatanPage /> },
        { path: 'master/kota-kabupaten', element: <KotaKabupatenPage /> },
        { path: 'master/kecamatan', element: <KecamatanPage /> },
        { path: 'kepegawaian/pegawai', element: <PegawaiPage /> },
        { path: 'kepegawaian/pegawai/import', element: <ImportPegawaiPage /> },
        { path: 'kepegawaian/pegawai-masuk', element: <PegawaiMasukPage /> },
        { path: 'kepegawaian/pegawai-keluar', element: <PegawaiKeluarPage /> },
        { path: 'kepegawaian/promosi', element: <PromosiPage /> },
        { path: 'kepegawaian/mutasi', element: <MutasiPage /> },
        { path: 'kepegawaian/laporan/promosi-mutasi', element: <LaporanPromosiMutasi /> },
        { path: 'kepegawaian/laporan/kinerja', element: <LaporanKinerja /> },
        { path: 'kepegawaian/laporan/kinerja-kantor', element: <LapKinerjaKantorPage /> },
        { path: 'kepegawaian/laporan/pegawai-kontrak', element: <LapPegawaiKontrakPage /> },
        { path: 'kepegawaian/laporan/absen-pegawai', element: <LaporanAbsen /> },
        { path: 'kepegawaian/laporan/history-absen-pegawai', element: <LaporanHistoryAbsen /> },
        { path: 'kepegawaian/laporan/history-cuti', element: <HistoryCutiPage /> },
        { path: 'kepegawaian/dokumen', element: <DokAllPegawaiPage /> },
        { path: 'kepegawaian/absen', element: <AbsenPage /> },
        { path: 'kepegawaian/absen-rumpun', element: <AbsenRumpunPage /> },
        { path: 'kepegawaian/birthday', element: <BirthdayPage /> },
        { path: 'kepegawaian/cuti/saldo-cuti', element: <SaldoCutiPage /> },
        { path: 'kepegawaian/cuti/cuti-request', element: <CutiRequestPage /> },
        { path: 'kepegawaian/cuti/saldo-cuti/import', element: <ImportSaldoCutiPage /> },
        { path: 'kepegawaian/cuti/process-cuti', element: <ProcessCutiPage /> },
        { path: 'user', element: <UserPage /> }
      ],
    },
    {
      path: '*',
      element: <Page404 />,
    },
  ]);

  return routes;
}
