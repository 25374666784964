import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { axiosPrivate } from '../../../../common/axiosPrivate';
import { InputField, CheckboxField, SelectField, DatePickerField, AutocompleteField } from '../../../../components/form-fields';

const jenisKelamins = [
  {
    value: 'Laki-Laki',
    label: 'Laki-Laki'
  },
  {
    value: 'Perempuan',
    label: 'Perempuan'
  }
];

const statusPerkawinans = [
  {
    value: 'Menikah',
    label: 'Menikah'
  },
  {
    value: 'Belum Menikah',
    label: 'Belum Menikah'
  }
];

const pendidikans = [
  {
    value: 'SD',
    label: 'SD'
  },
  {
    value: 'SMP',
    label: 'SMP'
  },
  {
    value: 'SMA',
    label: 'SMA'
  },
  {
    value: 'D1',
    label: 'D1'
  },
  {
    value: 'D2',
    label: 'D2'
  },
  {
    value: 'D3',
    label: 'D3'
  },
  {
    value: 'S1',
    label: 'S1'
  },
  {
    value: 'S2',
    label: 'S2'
  },
  {
    value: 'TS',
    label: 'Tidak Sekolah'
  },
];

const agamas = [
  {
    value: 'Islam',
    label: 'Islam'
  },
  {
    value: 'Protestan',
    label: 'Protestan'
  },
  {
    value: 'Katolik',
    label: 'Katolik'
  },
  {
    value: 'Hindu',
    label: 'Hindu'
  }
];

export default function ProfilForm(props) {
  const {
    values,
    formField: {
      kode,
      idAnggota,
      nama,
      namaTanpaGelar,
      jenisKelamin,
      tempatLahir,
      tglLahir,
      statusPerkawinan,
      agama,
      pendidikan,
      ukuranBaju,
      provinsi,
      kotaKab,
      kecamatan,
      alamat,
      provinsiDomisili,
      kotaKabDomisili,
      kecamatanDomisili,
      alamatDomisili,
      useDomisili
    }
  } = props;

  const [domisili, setDomisili] = useState(values?.useDomisili);
  const [provinsis, setProvinsis] = useState([]);
  const [kotaKabs, setKotaKabs] = useState([]);
  const [kecamatans, setKecamatans] = useState([]);
  const [kotaKabDomis, setKotaKabDomis] = useState([]);
  const [kecamatanDomis, setKecamatanDomis] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [selectedProvinsi, setSelectedProvinsi] = useState(values?.provinsi);
  const [selectedProvinsiDomi, setSelectedProvinsiDomi] = useState(values?.provinsiDomisili);
  
  const handleSetDomisili = (e) => {
    setDomisili(e.target.value !== 'true');
  }

  const onSetProvinsi = (value) => {
    if(value) {
      const selectedProvinsi = provinsis.find(provinsi => provinsi.value === value);

      axiosPrivate.get(`/region/kabupaten-kota/${selectedProvinsi.key}`)
      .then((res) => {
        const data = res?.data;

        setKotaKabs(data);
        setSelectedProvinsi(selectedProvinsi.key);
        setKecamatans([]);
      })
      .catch((error) => console.log(error))
    }
  }

  const onSetProvinsiDomi = (value) => {
    if(value) {
      const provinsi = provinsis.find(provinsi => provinsi.value === value);

      axiosPrivate.get(`/region/kabupaten-kota/${provinsi.key}`)
      .then((res) => {
        const data = res?.data;

        setKotaKabDomis(data);
        setSelectedProvinsiDomi(provinsi.key);
        setKecamatanDomis([]);
      })
      .catch((error) => console.log(error))
    }
  }

  const onSetKabKota = (value) => {
    if(value) {
      const selectedKabKota = kotaKabs.find(kotaKab => kotaKab.value === value);
      const key = selectedKabKota.key;
      
      axiosPrivate.get(`/region/kecamatan/${selectedProvinsi}/${key}`)
      .then((res) => {
        const data = res?.data;

        setKecamatans(data);
      })
      .catch((error) => console.log(error))
    }
  }

  const onSetKabKotaDomi = (value) => {
    if(value) {
      const kotaKab = kotaKabDomis.find(kotaKab => kotaKab.value === value);
      const key = kotaKab.key;
      
      axiosPrivate.get(`/region/kecamatan/${selectedProvinsiDomi}/${key}`)
      .then((res) => {
        const data = res?.data;

        setKecamatanDomis(data);
      })
      .catch((error) => console.log(error))
    }
  }

  useEffect(() => {
    setPageLoading(true);
    axiosPrivate.get('region/provinsi')
    .then((res) => {
      const data = res?.data;
      
      setProvinsis(data);
    })
    .catch((error) => console.log(error))
    .finally(() => {
      if(!!values?.kode) setPageLoading(false)
    });
  }, []);

  useEffect(() => {
    if (values?.provinsi) {
      const provinsi = provinsis.find(provinsi => provinsi.value === values.provinsi);

      axiosPrivate.get(`/region/kabupaten-kota/${provinsi?.key}`)
      .then((res) => {
        const data = res?.data;
        setKotaKabs(data);
      })
      .catch((error) => console.log(error))
    }
  }, [provinsis, values?.provinsi]);

  useEffect(() => {
    if (values?.provinsi && values?.kotaKab) {
      const provinsi = provinsis.find(provinsi => provinsi.value === values?.provinsi);
      const kotaKab = kotaKabs.find(kotaKab => kotaKab.value === values?.kotaKab);      

      axiosPrivate.get(`/region/kecamatan/${provinsi?.key}/${kotaKab?.key}`)
      .then((res) => {
        const data = res?.data;
        setKecamatans(data);
      })
      .catch((error) => console.log(error))
      .finally(() => setPageLoading(false));
    }
  }, [provinsis, kotaKabs, values?.provinsi, values?.kotaKab]);

  useEffect(() => {
    if (values?.provinsiDomisili) {
      const provinsi = provinsis.find(provinsi => provinsi.value === values?.provinsiDomisili);

      axiosPrivate.get(`/region/kabupaten-kota/${provinsi?.key}`)
      .then((res) => {
        const data = res?.data;
        setKotaKabDomis(data);
      })
      .catch((error) => console.log(error))
    }
  }, [provinsis, values?.provinsiDomisili]);

  useEffect(() => { 
    if (values?.provinsiDomisili && values?.kotaKabDomisili) {
      const provinsi = provinsis.find(provinsi => provinsi.value === values?.provinsiDomisili);
      const kotaKab = kotaKabDomis.find(kotaKab => kotaKab.value === values?.kotaKabDomisili);

      axiosPrivate.get(`/region/kecamatan/${provinsi?.key}/${kotaKab?.key}`)
      .then((res) => {
        const data = res?.data;
        setKecamatanDomis(data);
      })
      .catch((error) => console.log(error))
    }
  }, [provinsis, kotaKabDomis, values?.provinsiDomisili, values?.kotaKabDomisili]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <InputField
            name={kode.name}
            label={kode.label}
            readOnly={!!values?.kode}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={idAnggota.name} label={idAnggota.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={nama.name} label={nama.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={namaTanpaGelar.name} label={namaTanpaGelar.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={jenisKelamin.name}
            label={jenisKelamin.label}
            data={jenisKelamins}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={tempatLahir.name} label={tempatLahir.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePickerField
            name={tglLahir.name}
            label={tglLahir.label}
            format="dd/MM/yyyy"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={statusPerkawinan.name}
            label={statusPerkawinan.label}
            data={statusPerkawinans}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={pendidikan.name}
            label={pendidikan.label}
            data={pendidikans}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectField
            name={agama.name}
            label={agama.label}
            data={agamas}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={ukuranBaju.name} label={ukuranBaju.label} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutocompleteField
            name={provinsi.name}
            label={provinsi.label}
            data={provinsis}
            onSetValue={(value) => onSetProvinsi(value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutocompleteField
            name={kotaKab.name}
            label={kotaKab.label}
            data={kotaKabs}
            onSetValue={(value) => onSetKabKota(value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AutocompleteField
            name={kecamatan.name}
            label={kecamatan.label}
            data={kecamatans}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <InputField
            name={alamat.name}
            label={alamat.label}
            multiline
            rows={3}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxField
            name={useDomisili.name}
            label={useDomisili.label}
            onChange={handleSetDomisili}
          />
        </Grid>
        {domisili && 
          <>
            <Grid item xs={12} sm={12}>
              <AutocompleteField
                name={provinsiDomisili.name}
                label={provinsiDomisili.label}
                data={provinsis}
                onSetValue={(value) => onSetProvinsiDomi(value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AutocompleteField
                name={kotaKabDomisili.name}
                label={kotaKabDomisili.label}
                data={kotaKabDomis}
                onSetValue={(value) => onSetKabKotaDomi(value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AutocompleteField
                name={kecamatanDomisili.name}
                label={kecamatanDomisili.label}
                data={kecamatanDomis}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField
                name={alamatDomisili.name}
                label={alamatDomisili.label}
                multiline
                rows={3}
                fullWidth
              />
            </Grid>
          </>
        }
      </Grid>
    </>
  );
}
